








import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "settings-navigation",
  data() {
    return {
      links: [
        {
          label: "Procedures subtypes",
          href: "/nomenclators/procedures-subtypes",
        },
        {
          label: "Medical locations",
          href: "/nomenclators/medical-locations",
        },
        {
          label: "Ocupations",
          href: "/nomenclators/ocupations",
        },
        {
          label: "Accesories",
          href: "/nomenclators/accessories",
        },
        {
          label: "References",
          href: "/nomenclators/references",
        },
        {
          label: "Hold Motives",
          href: "/nomenclators/motives",
        },
      ],
    };
  },
  mounted() {
    this.links[3].label = this.dataCRM.nomenclators
      ? this.dataCRM.nomenclators.accessories
      : "Accessories";
  },
  computed: {
    ...mapState(["dataCRM"]),
  },
});
